
import { defineComponent, ref } from 'vue'
import PageAuth from '@/components/layout/PageAuth.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: {
    PageAuth,
    TmButton,
    TmFormLine,
  },
  setup() {
    const confirmation = ref({
      code: '',
    })

    return {
      confirmation,
    }
  },
})
